
.post {


  .link {
    background: #6a69ff;
    color: #ffffff;
    border-radius: 2px;
    font-weight: bold;
    padding: 1rem;
    margin-top: 1rem;
    border: solid 3px #222263;
    cursor: pointer;
    display: block; } }

