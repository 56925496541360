
.post-filters {
  display: flex;

  .post-filter {
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 500;
    margin-right: 1rem;
    opacity: 0.3;

    &.active {
      opacity: 1; }

    &:nth-child(1) {
      border-right: solid 2px black;
      padding-right: 1rem; } } }
