
.post-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .post-row-content {

    .title {
      font-size: 1.25rem;
      padding: 0;
      margin-top: 1rem;
      margin-bottom: 0.25rem;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
      color: inherit;
      display: block; } } }
