.text-input {
  display: block;
  padding: 0.6rem 1rem 0.6rem 1rem;
  border-radius: 3px;
  border: solid 3px #000000c9;
  width: 100%;
  margin-bottom: 0.5rem;
  color: #000000d6;
  transition: 0.2s all;
  font-size: 1rem;
  box-sizing: border-box;

  &:hover {
    background: #fbfbfb; } }
