.onboard-container {
  max-width: 500px;
  margin: 0 auto;

  .title {
    font-size: 1.25rem;
    font-weight: bold; }

  .submit-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .message {
      margin-right: 1rem;

      p {
        margin: 0;
        padding: 0; } } } }
