
.post-points {
  margin-right: 1rem;
  text-align: center;
  min-width: 2rem;
  font-weight: bold;
  position: relative;

  .points-img-container {
    cursor: pointer;

    img {
      max-width: 11px; } }

  .downvote {
    transform: rotate(180deg); } }
