
.app-layout {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .app-layout-inner {
    // background: #F9F9F9;
 } }    // border-radius: 0.25rem;

.header-container {
  @media (max-width: 500px) {
    flex-direction: column-reverse;

    .header {
      margin-top: 1rem;
      flex-direction: column;
      text-align: center;
      padding-left: 1rem;
      padding-right: 1rem;

      h1 {
        font-size: 1.5rem; }

      p {
        font-size: 1rem; } } } }

body {
  @media (max-width: 500px) {
    margin: 0.5rem !important;

    .app-layout {
      padding: 1rem; } } }
