
@import "./assets/styles/variables";

html {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  body {
    background: #F9F9F9;
    border-radius: 0.25rem;
    margin: 1.5rem;
    font-family: 'Roboto Mono', monospace;
    height: 100%;

    #root {
      background: #F9F9F9;
      border: #F9F9F9;
      border-radius: 0.25rem;
      margin-bottom: 1.5rem; } } }

.flex {
  display: flex; }

.flex-row {
  flex-direction: row; }

.flex-center {
  align-items: center; }

.flex-even {
  justify-content: space-evenly; }

.flex-between {
  justify-content: space-between; }

pre {
  font-weight: normal; }
