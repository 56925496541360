
@import "../../../../assets/styles/variables";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .header-links {
    margin-top: 0.5rem; }

  .logo-container {
    margin-right: 2rem;

    @media (max-width: $mobile-breakpoint-large) {
      margin-right: 0.5rem;

      img {
        max-width: 3rem; } } }

  .content-container {
    width: 100%;
    padding-right: 1.5em;

    h1,
    p {
      padding: 0;
      margin: 0; } } }

