.submit-button {
  padding: 1rem;
  border: none;
  background: #6a69ff;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  border-bottom: solid 9px darken(#6a69ff, 10%);

  &:hover {
    background: darken(#6a69ff, 3%); }

  &.negative {
    background: #ea5f8b;
    border-bottom: solid 3px darken(#ea5f8b, 10%);

    &:hover {
      background: darken(#ea5f8b, 3%); } } }
